.reviw-log-container main{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2em;
}

.reviw-log-container .logs-container {
    margin-top: 4em;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.reviw-log-container .log-header{
    width: 70%;
    margin-bottom: 1em;
    font-weight: bold;
    font-size: 18px;
    padding: 0 3%;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
}

.reviw-log-container .logs-container .log{
    background-color: rgba(213, 234, 206, 0.54);
    width: 70%;
    padding: 2em 3%;
    border-radius: 8px;
    margin-bottom: 2em;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    align-items: center;
    grid-gap: .5em;
}

.reviw-log-container .button{
    padding: 0.6em 1.9em;
    color: var(--font);
    font-size: 18px;
    font-weight: bold;
    border-radius: 4px;
    transition: 2ms;
    cursor: pointer;
    text-decoration: none;
}

.reviw-log-container .button:hover{
    background-color: var(--lighter_detail);
}

.reviw-log-container .review-options-pending-button{
    background-color: rgba(213, 234, 206, 0.54);
    border: 1px solid rgba(213, 234, 206, 0.54);
    color: var(--background);
    font-weight: normal;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.reviw-log-container .review-options-finished-button{
    background-color: rgba(213, 234, 206, 0.54);
    border: 1px solid rgba(213, 234, 206, 0.54);
    color: var(--background);
    font-weight: normal;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.reviw-log-container .review-options-review-logs-button{
    background-color: var(--font);
    border: 1px solid var(--font);
    color: var(--background);
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

@media only screen and (max-width: 900px){
    .reviw-log-container .review-options{
        margin-bottom: 2em;
    }
    
    .reviw-log-container .review-options-pending-button{
        padding: .6em .6em;
        font-size: 15px;
    }
    
    .reviw-log-container .review-options-finished-button{
        font-size: 15px;
        padding: .6em .6em;
    }

    .reviw-log-container .log-header{
        display: none;
    }

    .reviw-log-container .logs-container .log{
        display: flex;
        flex-direction: column;
        width: 90%;
    }
}