.access-skeleton-container{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.access-skeleton-container .access-skeleton{
    width: 90%;
    height: 13em;
    padding: 2em 3em;
    border-radius: 8px;
}