.dashboard-container {
    height: 100vh;
}

.dashboard-container .dashboard-menu-container{
    display: flex;
    height: 100%;
}

.dashboard-container .access-indicators{
    width: 60%;
    padding: 2em;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: 25em;
}

.dashboard-container .access-indicators-title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(213, 234, 206, 0.54);
    width: 15em;
    height: 8em;
    text-align: center;
    border-radius: 4px;
    padding: 1em;
}



.dashboard-container .access-indicators-title h3{
    color: var(--background);
}