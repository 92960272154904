* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root{
  --background: #1b3c20;
  --secondary: #5cad4d;
  --primary: #93c83d;
  --primary_detail: #d5eace;
  --darker_detail: #a9aaad;
  --lighter_detail: #5c6569;
  --card: #4F4F4F;
  --font: #ffff;
  --danger: #CF0000;
}

#root{
  height: 100vh;
}

body{
  background-color: var(--background);
  color: var(--font);
  font-family: 'Lato', sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  width: 100%;
}