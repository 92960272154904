.managers-list-container {
    height: 100%;
}

.managers-list-container .managers-list-menu-container{
    display: flex;
    height: 100%;
}

.managers-list-container main{
    width: 100%;
    padding: 2em;
}

.managers-list-container .filters{
    margin: 1em auto;
    margin-bottom: 2em;
    display: flex;
    align-items: center;
}

.managers-list-container .filters select{
    height: 3em;
    font-size: 14px;
    padding: .2em;
    border-radius: 4px;
    margin: 0 1em
}

.managers-list-container .filters input{
    height: 3em;
    font-size: 14px;
    padding: .2em;
    border-radius: 4px;
    margin: 0 1em
}

.managers-list-container .managers-header{
    width: 95%;
    margin-bottom: 1em;
    font-weight: bold;
    font-size: 18px;
    padding: 0 3%;
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr;  
    grid-auto-rows: 2em;
}

.managers-list-container .access{
    background-color: var(--card);
    width: 95%;
    padding: 2em 3%;
    border-radius: 8px;
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    margin-bottom: 2em;
}

.managers-list-container .actions_column {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
}