.upload-data-container{
    height: 100%;
}

.upload-data-container .upload-data-menu-container{
    display: flex;
    height: 100%;
}

.upload-data-container main{
    width: 100%;
    padding: 2em;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3em;
    margin-top: 8em;
}

.upload-data-container .file-content{
    background: var(--font);
    border-radius: 4px;
    padding: 20px;
    color: black;
    margin-top: 1em;
}

@media only screen and (max-width: 900px){
    .upload-data-container main{
        grid-template-columns: 1fr;
    }
}