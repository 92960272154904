.review-periods-container {
    height: 100%;
}

.review-periods-container .review-periods-menu-container{
    display: flex;
    height: 100%;
}

.review-periods-container main{
    width: 100%;
    padding: 2em;
}

.review-periods-container .review-periods-info{
    width: 85%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 3.5em;
}

.review-periods-container .button{
    padding: 0.6em 1.9em;
    color: var(--background);
    background-color: var(--font);
    font-size: 18px;
    font-weight: bold;
    border-radius: 4px;
    transition: 2ms;
    cursor: pointer;
    text-decoration: none;
}

.review-periods-container .button:hover{
    background-color: var(--secondary);
    color: var(--font);
}

.review-periods-container .periods-lists{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; 
}

.review-periods-container .periods-header{
    width: 85%;
    margin-bottom: 1em;
    font-weight: bold;
    font-size: 18px;
    padding: 0 3%;
    display: flex;
    justify-content: space-around;
}

.review-periods-container .period{
    background-color: var(--card);
    width: 85%;
    padding: 2em 3%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 2em;
}

