.period-create-container {
    height: 100%;
}

.period-create-container .period-create-menu-container{
    display: flex;
    height: 100%;
}

.period-create-container main{
    width: 100%;
    padding: 2em;
}

.period-create-container form{
    background-color: var(--card);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30em;
    padding: 2em;
    height: 20em;
    border-radius: 4px;
    margin-top: 3em;
}

.period-create-container input{
    padding: 1.1em 0.5em;
    width: 25em;
    border-radius: 4px;
    margin-bottom: 1.5em;
}

.period-create-container .button{
    padding: 0.6em 0;
    background-color: var(--secondary);
    color: var(--font);
    font-size: 18px;
    width: 18.7em;
    font-weight: bold;
    border-radius: 4px;
    transition: 2ms;
    cursor: pointer;
    text-decoration: none;
}

.period-create-container .button:hover{
    color: var(--background);
    background-color: var(--font);
}