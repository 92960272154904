.tools-list-container {
  height: 100vh;
}

.tools-list-container .tools-list-menu-container {
  display: flex;
  height: 100%;
}

.tools-list-container main {
  width: 100%;
  padding: 2em;
}

.tools-list-container .filters {
  margin-block: 1rem 2.5rem;
  display: flex;
  align-items: center;
}

.tools-list-container .filters select {
  height: 3em;
  font-size: 14px;
  padding: 0.2em;
  border-radius: 4px;
  margin: 0 1em;
}

.tools-list-container .filters input {
  height: 3em;
  font-size: 14px;
  padding: 0.2em 0.5em;
  border-radius: 4px;
  min-width: 25rem;
}

.tools-list-container .apps-header {
  width: 100%;
  margin-bottom: 1em;
  font-weight: bold;
  font-size: 18px;
  padding: 0 3%;
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1.5fr 1fr 1fr 1fr 1fr 1fr;
  grid-auto-rows: 2em;
  grid-gap: 1em;
}

.tools-list-container .access {
  background-color: var(--card);
  width: 100%;
  padding: 2em 3%;
  border-radius: 8px;
  display: grid;
  grid-template-columns:  0.5fr 1fr 1fr 1fr 1fr 1.5fr 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  margin-bottom: 2em;
  grid-gap: 1em;
}

.tools-list-container .download {
  font-size: 20px;
  cursor: pointer;
}

.tools-list-container .delete {
  font-size: 20px;
  margin-left: 2px;
  cursor: pointer;
}

.tools-list-container .actions_column {
  display: flex;
  justify-content: space-around;
}

.tools-list-container .open {
  cursor: pointer;
}