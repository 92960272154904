.drop-container{
   border: 1px dashed #ddd;
   border-radius: 4px;
   cursor: pointer;
   transition: height 0.2 ease; 
}

.drag-active{
    border-color: #78e5d5;
}

.drag-reject{
    border-color: #e57878;
}

.upload-message{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1;
    padding: 15px 0;
}

.upload-message-default{
    color: #999
}

.upload-message-error{
    color: #e57878
}

.upload-message-success{
    color: #78e5d5
}