.finished-review-container main{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2em;
}

.finished-review-container .access-container {
    margin-top: 2em;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.finished-review-container .finished-review-filters{
    margin-top: 1.5em;
}

.finished-review-container .finished-review-filters select{
    height: 3em;
    font-size: 14px;
    padding: .2em;
    border-radius: 4px;
    margin: 0 1em
}

.finished-review-container .finished-review-filters input{
    height: 3em;
    font-size: 14px;
    padding: .2em;
    border-radius: 4px;
    margin: 0 1em
}

.finished-review-container .batch-actions{
    margin-top: 1em;
}

.finished-review-container .confirm-button{
    background-color: var(--secondary);
    border: 1px solid var(--secondary);
    margin-right: .5em;
}

.finished-review-container .access-header{
    width: 85%;
    margin-bottom: 1em;
    font-weight: bold;
    font-size: 18px;
    padding: 0 3%;
    display: grid;
    grid-template-columns: .3fr 2fr 1fr 1fr 1fr 1fr 2fr;
}

.finished-review-container .access-header p{
    cursor: pointer;
}

.finished-review-container .access-header .order{
    color: #49634d;
}

.finished-review-container .access-header .header-title:hover + .order{
    color: var(--font)
}

.finished-review-container .access-header .order:hover{
    color: var(--font)
}


.finished-review-container .access-header .header-check{
    height: 1.5em;
    cursor: pointer;
}

.finished-review-container .access-container .access{
    background-color: rgba(213, 234, 206, 0.54);
    width: 85%;
    padding: 2em 3%;
    border-radius: 8px;
    margin-bottom: 2em;
    display: grid;
    grid-template-columns: .3fr 2fr 1fr 1fr 1fr 1fr 2fr;
    align-items: center;
    grid-gap: .5em;
}

.finished-review-container .access .access-tool{
    font-weight: normal;
}

.finished-review-container .access .access-role{
    color: var(--background);
    font-weight: bold;
}

.finished-review-container .button{
    padding: 0.6em 1.9em;
    color: var(--font);
    font-size: 18px;
    font-weight: bold;
    border-radius: 4px;
    transition: 2ms;
    cursor: pointer;
    text-decoration: none;
}

.finished-review-container .button:hover{
    background-color: var(--lighter_detail);
}

.finished-review-container .undo-delete-button{
    background-color: var(--secondary);
    border: 1px solid var(--secondary);
    width: 3em;
    height: 2.6em;
    padding: 0;
    margin-left: 1em;
}

.finished-review-container .review-options-pending-button{
    background-color: rgba(213, 234, 206, 0.54);
    border: 1px solid rgba(213, 234, 206, 0.54);
    color: var(--background);
    font-weight: normal;
    border-radius: 0;
}

.finished-review-container .review-options-finished-button{
    background-color: var(--font);
    border: 1px solid var(--font);
    color: var(--background);
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.finished-review-container .review-options-review-logs-button{
    background-color: rgba(213, 234, 206, 0.54);
    border: 1px solid rgba(213, 234, 206, 0.54);
    color: var(--background);
    font-weight: normal;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.finished-review-container .access-actions{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.finished-review-container .access-actions-container{
    display: flex;
    align-items: center;
    width: 20em;
}

.finished-review-container .access-status{
    background-color: var(--font);
    color: var(--background);
    border-radius: 4px;
    padding: .5em;
    width: 7.6em;
    text-align: center;
}

.finished-review-container .access-justification{
    width: 18em;
    margin-top: 1em;
    color: var(--background);
    font-size: 12px;
}

@media only screen and (max-width: 900px){
    .finished-review-container .review-options{
        margin-bottom: 2em;
    }
    
    .finished-review-container .review-options-pending-button{
        padding: .6em .6em;
        font-size: 15px;
    }
    
    .finished-review-container .review-options-finished-button{
        font-size: 15px;
        padding: .6em .6em;
    }

    .finished-review-container .finished-review-filters{
        display: flex;
        flex-direction: column;
    }

    .finished-review-container .finished-review-filters select{
        margin-bottom: 2em;
    }

    .finished-review-container .access-header{
        display: none;
    }

    .finished-review-container .access-container .access{
        display: flex;
        flex-direction: column;
        width: 90%;
    }

    .finished-review-container .access-actions{
        margin-top: 2em;
        width: 100%;
        align-items: center;
    }

    .finished-review-container .access-actions-container{
        justify-content: center;
    }

    .finished-review-container .access-justification{
        width: 70%;
        text-align: center;
    }
}