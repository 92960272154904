header{
    background-color: var(--secondary);
    padding: 1em 2em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

header img{
    width: 10em;
}

header .header-actions{
    display: flex;
}

header .header-help{
    text-decoration: none;
    font-weight: bold;
    font-size: 20px;
    color: var(--font);
    margin-right: 2.5em;
    display: flex;
    align-items: center;
}

header .header-help:hover{
    color: var(--background);
}

header .logout-button{
    padding: 0.6em 1em;
    color: var(--font);
    font-size: 18px;
    font-weight: bold;
    border-radius: 4px;
    transition: 2ms;
    cursor: pointer;
    background-color: var(--background);
    border: 1px solid var(--background);
}

header .logout-button:hover{
    background-color: var(--lighter_detail);
}

header.header-test{
    background-color: var(--lighter_detail);
}

@media only screen and (max-width: 800px){
    header .header-help{
        margin: 0 .5em;
    }
}