.files-container {
    /* height: 100vh; */
}

.files-container .files-menu-container{
    display: flex;
    height: 100%;
}

.files-container main{
    width: 100%;
    padding: 2em;
}

.files-container .files-info{
    width: 85%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto 3.5em;
}

.files-container .files-actions {
    display: flex;
    justify-content: space-around;
    max-width: 45rem;
}

.files-container .files-actions button{
    border: 0 solid transparent;
}

.files-container .filters {
    width: 100%;
    margin-block: 2.5rem 0;
    display: flex;
    align-items: center;
}

.files-container .filters input {
    height: 3em;
    font-size: 14px;
    padding: 0.2em 0.5em;
    border-radius: 4px;
    min-width: 25rem;
}

.files-container .button{
    padding: 0.6em 1.9em;
    color: var(--background);
    background-color: var(--font);
    font-size: 18px;
    font-weight: bold;
    border-radius: 4px;
    transition: 2ms;
    cursor: pointer;
    text-decoration: none;
}

.files-container .disabled-button{
    background-color: var(--lighter_detail);
    cursor: not-allowed;
}

button.button:disabled{
    background-color: var(--lighter_detail);
    cursor: not-allowed;
}

.files-container .button:hover{
    background-color: var(--secondary);
    color: var(--font);
}

.files-container .download-file-button {
    margin-inline: 1rem;
    background-color: var(--secondary);
    color: var(--font);
}

.files-container .download-file-button:last-child {
    margin-inline: 0;
}

.files-container .download-file-button:hover{
    background-color: var(--font);
    color: var(--background);
}

.files-container .files-lists {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; 
}

.files-container .files-header{
    width: 85%;
    margin-bottom: 1em;
    font-weight: bold;
    font-size: 18px;
    padding: 0 3%;
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 2.5fr .5fr;
}

.files-container .file {
    background-color: var(--card);
    width: 85%;
    padding: 2em 3%;
    border-radius: 8px;
    margin-bottom: 2em;
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 2.5fr .5fr;
}

.files-container .file-name input{
    margin-right: 0.75em;
}

.files-container .file-hash{
    display: flex;
    flex-wrap: wrap;
    width: 10px;
    font-size: 9px;
}

.files-container .file-link{
    cursor: pointer;
}

.files-container .no-files-alert {
    margin-top: 3rem;
}