.accesses-list-container {
    height: 100%;
}

.accesses-list-container .accesses-list-menu-container {
    display: flex;
}

.accesses-list-container main {
    width: 100%;
    padding: 2em;
}

.accesses-list-container .numbers {
    margin-top: .5em;
}

.accesses-list-container  .filters {
    margin: 2em auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-width: 100%;
}

.accesses-list-container  .filters select {
    height: 3em;
    font-size: 14px;
    padding: .2em;
    border-radius: 4px;
    margin: 0 1em
}

.accesses-list-container  .filters input {
    height: 3em;
    font-size: 14px;
    padding: .2em;
    border-radius: 4px;
    margin: 0 1em
}

.accesses-list-container .accesses-header {
    width: 100%;
    margin-bottom: 1em;
    font-weight: bold;
    font-size: 18px;
    padding: 0 3%;
    display: grid;
    grid-template-columns: .5fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr .5fr;
    grid-auto-rows: 2em;
    grid-gap: 1em;
}

.accesses-list-container .accesses-header .order {
    color: #49634d;
    cursor: pointer;
}

.accesses-list-container .accesses-header .header-title:hover + .order {
    color: var(--font);
}

.accesses-list-container .accesses-header .order:hover {
    color: var(--font)
}

.accesses-list-container .accesses-list {
    max-width: 100%;
}

.accesses-list-container .access {
    background-color: var(--card);
    width: 100%;
    padding: 2em 3%;
    border-radius: 8px;
    margin-bottom: 2em;
    display: grid;
    grid-template-columns: .5fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr .5fr;
    align-items: center;
    grid-gap: 1em;
}

.accesses-list-container .access .access-status {
    color: var(--secondary);
}

.accesses-list-container .access-justification {
    font-size: 12px;
    margin-top: 1em;
}

.accesses-list-container .access-reviewer {
    font-size: 12px;
    color: var(--darker_detail);
}

.footer button {
    color: white;
}
.footer .MuiPaginationItem-ellipsis {
    color: white;
}
