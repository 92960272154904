.menu-container{    
    background-color: rgba(213, 234, 206, 0.54);
    width: 16em;
    display: flex;
    justify-content: center;
}

.menu-container .menu-options{
    display: flex;
    flex-direction: column;
    margin-top: 2em;
}

.menu-container .menu{
    color: var(--font);
    text-decoration: none;
    margin-top: 1.5em;
    border-bottom: 1px solid var(--background);
    padding-bottom: 1em;
}

.menu-container .menu:hover{
    color: var(--background);
}