circle {
    fill: transparent;
    stroke: #2f3d4c;
    stroke-width: 10;
    stroke-linecap: round;
    transform-origin: 50% 50%;
    stroke-dasharray: 283;
    stroke-dashoffset: 75
}

.svg-loading {
    max-width: 25px;
}

@keyframes circle--animation {
0%,
25% {
    stroke-dashoffset: 280;
    transform: rotate(0);
}

50%,
75% {
    stroke-dashoffset: 75;
    transform: rotate(45deg);
}

100% {
    stroke-dashoffset: 280;
    transform: rotate(360deg);
}
}

circle {
    animation: 1.4s ease-in-out infinite both circle--animation;
}
@keyframes svg--animation {
    0% {
        transform: rotateZ(0deg);
    }
    100% {
        transform: rotateZ(360deg)
    }
}

.svg-loading {
    animation: 2s linear infinite svg--animation;
}

.file-list-container{
    margin-top: 20px;
}

.file-list-container li{
    display: grid;
    grid-template-columns: 2.5fr 1fr 1fr 1fr;
    grid-gap: .5em;
    align-items: center;
    color: #444;
    margin-top: 15px
}

.file-list-container .file-list-file-info{
    display: flex;
    align-items: center;
}

.file-list-container .file-list-file-info div{
    display: flex;
    flex-direction: column;
}

.file-list-container .file-list-file-info div span{
    font-size: 12px;
    color: #999;
    margin-top: 5px
}