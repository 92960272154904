.login-container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.login-container main{
    background-color: var(--secondary);
    border-radius: 8px;
    padding: 2em;
    width: 40em;
    height: 20em;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.login-container .logo{
    width: 13em;
    margin-bottom: 4em;
}

.login-container .send-button{
    background-color: var(--background);
    color: var(--font);
    padding: 1em 8em;
    font-size: 20px;
    font-weight: bold;
    border-radius: 4px;
    border: 1px solid var(--background);
    transition: 2ms;
    cursor: pointer;
    text-decoration: none;
}

.login-container .send-button:hover{
    background-color: var(--lighter_detail);
}

.login-container .loading-button{
    background-color: var(--lighter_detail);
    color: var(--font);
    padding: 1em 6.2em;
    font-size: 20px;
    font-weight: bold;
    border-radius: 4px;
    border: 1px solid var(--background);
    transition: 2ms;
    cursor: wait;
}

@media only screen and (max-width: 800px){
    .login-container main{
        width: 90%;
    }

    .login-container .send-button{
        padding: 1em 5em;
        width: 100%;
    }
}